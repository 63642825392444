import { defineStore } from 'pinia'
import axios from 'axios'

const apiUrl = `${process.env.VUE_APP_API_URL}`;

export const useTagStore = defineStore('tag', {
    state: () => ({ 
        items: []
    }),
    actions: {
        async fetchAll() {
            const response = await axios.get(`${apiUrl}/tags`)
            this.items = response.data.tags
        }
    },
})