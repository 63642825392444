import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faArrowLeft, faLink } from '@fortawesome/free-solid-svg-icons'
import router from "./router";
import App from './App.vue'
import '@/assets/css/tailwind.css'

/* add icons to the library */
library.add(faArrowRight, faArrowLeft, faLink)

const pinia = createPinia()
const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://b6063a3d29a64ec5bb540c6c1432444b@o4503965551689728.ingest.sentry.io/4503965584916480",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["funquestions.live", "funquestions.net"],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

app.use(router);
app.use(pinia)
app.mount('#app')