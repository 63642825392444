<script>
    import { defineComponent } from "vue"
    import { useQuestionStore } from '@/stores/question';
    import QuestionSuggestionForm from "@/components/QuestionSuggestionForm.vue"
    import logo from "@/assets/logo.png"

    export default defineComponent({
        name: 'NavBar',
        components: {
            QuestionSuggestionForm,
        },
        data() {
            return {
                logo
            }
        },
        methods: {
            createQuestionSuggestion(question) {
                const questionStore = useQuestionStore();
                questionStore.create(question);
                questionStore.fetchAll();

                document.getElementById('question-suggestion-modal').checked = false;
            }
        }
    })
</script>

<template>
    <nav>
        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-5">
            <div class="relative flex h-16 items-center justify-between">
                <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div class="flex flex-shrink-0 items-center">
                        <RouterLink to="/">
                            <img class="block w-36 h-auto" :src="logo" alt="fun questions">
                        </RouterLink>
                    </div>
                    <div class="justify-end grow text-right">
                        <!--label for="question-suggestion-modal" class="btn btn-outline  modal-button">Suggest a question</label-->

                        <input type="checkbox" id="question-suggestion-modal" class="modal-toggle" />
                        <label for="question-suggestion-modal" class="modal cursor-pointer">
                            <label class="modal-box relative" for="">
                                <QuestionSuggestionForm @submit="createQuestionSuggestion"/>
                            </label>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>