import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import QuestionPage from "@/pages/QuestionPage.vue";

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage,
    },
    {
      path: "/q/:id",
      name: "question",
      component: QuestionPage,
    }
  ],
});

export default router;
