import { defineStore } from 'pinia'
import axios from 'axios'
import axiosRetry from 'axios-retry'

const apiUrl = `${process.env.VUE_APP_API_URL}`;

const fetchClient = axios.create({ baseURL: apiUrl});
axiosRetry(fetchClient, { 
    retries: 3,
    shouldResetTimeout: true,
    retryCondition: () => true 
});

async function fetchAll(query = {}) {
    const response  = await fetchClient.get(`/questions`, { params: query })
    return response.data?.questions
}

async function fetch(id) {
    const response  = await fetchClient.get(`/questions/${id}`)
    return response.data?.question
}

export const useQuestionStore = defineStore('question', {
    state: () => ({ 
        items: [],
        item: null
    }),
    actions: {
        async fetchAll(query = {}) {
            this.items = await fetchAll(query)
        },
        async fetch(id) {
            this.item = await fetch(id)
        },
        async fetchFirst(id, query = {}) {
            const question = await fetch(id);

            if(!question) {
                return
            }

            const questions = await fetchAll(query);
                        
            this.items = [question, ...questions.filter(item => item.id !== question.id)];
            this.item = question;

            return question;
        },
        async create(question) {
            const response  = await axios.post(`${apiUrl}/questions`, {
               title: question.title,
               description: question.description,
            })
            this.items.unshift(response.data.question);
        }
    },
})