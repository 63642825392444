<script>
    import { defineComponent } from "vue"
    //import router  from "@/router";
    import { useQuestionStore } from '@/stores/question';
    import { useTagStore } from '@/stores/tag';
    import NavBar from "@/components/NavBar.vue";
    import QuestionDeck from "@/components/QuestionDeck.vue"

    export default defineComponent({
        name: 'HomePage',
        components: {
            NavBar,
            QuestionDeck,
        },
        setup(){
            const questionStore = useQuestionStore();
            const tagStore = useTagStore();

            questionStore.fetchAll();
            tagStore.fetchAll();

            return { questionStore, tagStore };
        },
        methods: {
            fetchQuestionsForCategory(category) {
                const questionStore = useQuestionStore();
                questionStore.fetchAll({
                    tag: category
                });
            },
            onDeckCompleted(category) {
                const questionStore = useQuestionStore();
                questionStore.items = [];
                questionStore.fetchAll({
                    tag: category
                });
            },
            onQuestionChanged() {
                //router.push({ name: 'question', params: { id : question.slug } })
            }
        }
    })
</script>

<template>
    <NavBar/>
    <section class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex justify-center">
            <QuestionDeck 
                :questions="questionStore.items" 
                :tags="tagStore.items" 
                @categoryChanged="fetchQuestionsForCategory"
                @deckCompleted="onDeckCompleted"
                @questionChanged="onQuestionChanged"/>
        </div>
    </section>
    <footer style="position: absolute; bottom: 1rem; right:0; left: 0;">Made with &hearts; in Halifax, NS | <a href="https://campfire.canny.io/" target="_blank">Suggest a Question/Feature &#128522;</a></footer>
</template>
  