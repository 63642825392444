<script>
    import { defineComponent } from "vue"

    export default defineComponent({
        name: 'QuestionSuggestionForm',
        components: {},
        data() {
            return {
                title: '',
                description: ''
            }
        },
        methods: {
            submit() {
                this.$emit('submit', {
                    title: this.title,
                    description: this.description
                });

                this.title = '';
                this.description = '';
            }
        }
    })
</script>
    
<template>
    <div class="m-7">
        <h1 class="my-7 text-xl font-bold text-center">Suggest a question</h1>
        <form class="px-10 w-full" @submit.stop.prevent="submit">
            <fieldset class="flex flex-col my-5">
                <label className="label text-sm font-bold sr-only">Title</label>
                <input v-model="title" type="text" required className="input input-bordered w-full max-w-xs rounded-md" placeholder="Title" />
            </fieldset>
            <fieldset class="flex flex-col my-5">
                <label className="label text-sm font-bold sr-only">Description</label>
                <textarea v-model="description" className="textarea w-full input-bordered max-w-xs resize-none rounded-md" placeholder="Description"/>
            </fieldset>
            <fieldset class="flex flex-col mt-7 justify-center">
                <button class="btn" type="submit" @submit.stop>Submit</button>
            </fieldset>
        </form>
    </div>
</template>