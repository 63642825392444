<script>
import { defineComponent } from "vue"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import loadingImage from "@/assets/question-deck-loading.png"

const defaultCategory = "1";

export default defineComponent({
    name: 'QuestionDeck',
    emits: ['categoryChanged', 'questionChanged', 'deckCompleted'], 
    components: {
        FontAwesomeIcon,
    },
    props: {
        questions: {
            type: Array
        },
        tags: {
            type: Array
        },
        delayInMs: {
            type: Number,
            default: 3000
        }
    },
    watch: { 
        questions: {
            handler() {
                this.activeIndex = 0;
            },
            immediate: true
        },
        'toast.message': {
            handler() {
                if(this.toast.timer) {
                    clearTimeout(this.toast.timer);
                }
                this.toast.timer = setTimeout(() => this.toast.message = '', 1500)
            }
        }
    },
    data() {
        return {
            loadingImage,
            categoryMenuValue: defaultCategory,
            activeIndex: 0,
            show: false,
            toast: {
                message: null,
                timer: null
            }
        }
    },
    mounted() {
        setTimeout(() => { this.show = true}, this.delayInMs)
    },
    computed: {
        activeQuestion() {
            return this.questions.length ? this.questions[this.activeIndex] : null
        },
        selectedCategory() {
            return this.categoryMenuValue == defaultCategory ? undefined : this.categoryMenuValue
        }
    },
    methods: {
        next() {
            if(this.activeIndex === this.questions.length - 1) {
                this.$emit('deckCompleted', this.selectedCategory);
            } else {
                this.activeIndex = this.activeIndex + 1;
            }

            this.questionChanged();
        },
        previous() {
            if(this.activeIndex === 0) {
                return;
            } 

            this.activeIndex = this.activeIndex - 1;

            this.questionChanged();
        },
        categoryChanged() {
            this.$emit('categoryChanged', this.selectedCategory)
        },
        questionChanged() {
            this.$emit('questionChanged', this.activeQuestion)
        },
        async copyURL() {
            try {
                await navigator.clipboard.writeText(`${window.location.origin}/q/${this.activeQuestion.slug}`);
                this.toast.message = '😁 Link copied'
            } catch(e) {
                console.log('Failed to copy');
            }
        }
    }
})
</script>
    
<template>
    <div class="my-7 touch-pan-x touch-pan-y">
        <div v-if="questions?.length && show">
            <div class="card w-96 shadow-sm h-96 border-2 bg-white">
                <figure></figure>
                <div class="card-body items-center justify-center h-full">
                    <div class="flex w-full justify-end">
                        <button class="btn btn-ghost h-2" @click="copyURL" >
                            <font-awesome-icon icon="fa-solid fa-link" />
                        </button>
                    </div>
                    <h2 class="card-title text-2xl grow">{{activeQuestion.title}}</h2>
                    <div class="card-actions justify-between w-full">
                        <button class="btn btn-ghost" @click="previous" :class="{ invisible: activeIndex === 0 }">
                            <font-awesome-icon icon="fa-solid fa-arrow-left" />
                            <span class="sr-only">Previous</span>
                        </button>
                        <button class="btn btn-ghost" @click="next">
                            <font-awesome-icon icon="fa-solid fa-arrow-right" />
                            <span class="sr-only">Next</span> 
                        </button>
                    </div>
                </div>
            </div>
            <select class="select select-secondary w-full max-w-xs mt-7 text-center" 
                style="text-align-last:center;"
                v-if="tags?.length" 
                @change="categoryChanged($event)" 
                v-model="categoryMenuValue">
                <template v-for="item in tags" :key="item.id">
                    <option :value="item.id" :label="item.name">{{item.name}}</option>
                </template>
            </select>
        </div>
        <div v-if="!questions?.length || !show">
            <div class="card w-96 shadow-sm h-96 border-2 bg-white">
                <div class="card-body items-center justify-center h-full opacity-50">
                    <div class="animate-pulse w-full h-full flex justify-center items-center flex-col">
                        <img class="block w-16 h-auto" :src="loadingImage" alt="loading"/>
                    </div>
                </div>
            </div>
            <select class="animate-pulse select select-secondary w-full max-w-xs mt-7 text-center appearance-none outline-none" disabled>
            </select>
        </div>
    </div>
    
    <div class="toast z-50" :class="{ invisible: !toast?.message }">
        <div class="alert alert-info bg-success-content">
            <div>
                <span class="text-slate-50">{{toast?.message}}</span>
            </div>
        </div>
    </div>
</template>